import { useState, useEffect } from 'react'
import { fetchAlternativesData } from './alternatives_api'
import { getHash } from '../../utils/Crypto'

export const useAlternativesData = (options: any) => {
  const [alternativesData, setAlternativesData] = useState(undefined)

  const signature = getHash(options)

  useEffect(() => {
    fetchAlternativesData(options).then(response => {
      if (response.error > 0) {
        setAlternativesData({ error: response.data })
      } else {
        setAlternativesData(response.data)
      }
    })
  }, [signature])

  return alternativesData
}
