import {env} from '../../../environment'
import { mapMaterialToApiGroup } from '../../utils/Mapping'

export const fetchAlternativesData = (options: any) => {
  const material = mapMaterialToApiGroup(options.material)

  const url =
    env.getServiceHost() +
    'products/alternatives?version=6&motiveId=' +
    options.motiveId +
    '&type=' +
    material +
    '&language=' +
    env.getLanguage()

  return fetch(url).then(response => response.json())
}
